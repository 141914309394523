import * as React from "react"

function UhSpotMeter(props) {
    const width = props.width || 143;
    const height = props.height || 212;
  return (
    <svg
      width= {width}
      height={height}
      viewBox="0 0 143 212"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.646 189.712v18.271c0 4.568 9.718 4.568 9.718 0v-18.271h-9.718zM85.155 189.712v18.271c0 4.151 9.717 4.151 9.717 0v-18.271h-9.717z"
        fill="#0056D6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.556 119s-1.415 36.277.77 46.219c2.183 9.942 6.6 22.338 21.957 28.846 15.358 6.507 26.462 6.205 40.888-.824 14.425-7.03 20.183-21.963 21.188-30.256 1.005-8.293 0-43.431 0-43.431L25.556 119z"
        fill="#0056D6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.397 119s-1.158 32.547.629 41.466c1.787 8.92 5.4 20.042 17.962 25.88 12.563 5.838 21.647 5.567 33.448-.739 11.8-6.307 16.51-19.705 17.332-27.145.822-7.44 0-38.965 0-38.965L33.397 119z"
        fill="#96B0FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.784 157.009c-2.584-.032-5.095 2.082-5.017 4.429.077 2.347 2.164 4.602 4.867 4.635 2.703.033 4.943-2.191 4.867-4.503-.077-2.312-2.133-4.529-4.717-4.561zM48.96 157.009c-4.6 0-4.6 8.621 0 8.621s13.07.131 17.426 0c4.357-.13 4.357-8.621 0-8.621H48.96zM51.278 138.528c-7.928 0-7.928 10.22 0 10.22H84.39c7.917 0 7.917-10.22 0-10.22H51.278z"
        fill="#0056D6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.04 67.02C-.8 45.907 11.66 28.12 18.239 21.5 24.815 14.878 43.568-.404 68.709.007c25.14.412 42.377 10.092 49.979 15.936 7.601 5.845 23.642 21.937 23.642 46.571 0 24.634-3.039 31.284-18.252 46.702s-30.506 21.382-50.994 21.382-38.257-6.797-50.285-17.131C10.77 103.135.882 88.131.04 67.019z"
        fill="#FB4141"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.265 66.986C8.531 48.742 19.413 33.37 25.157 27.648c5.744-5.72 22.122-18.927 44.078-18.572 21.956.356 37.009 8.721 43.648 13.772 6.638 5.05 20.647 18.957 20.647 40.246 0 21.288-2.654 27.035-15.94 40.359-13.286 13.324-26.642 18.478-44.534 18.478-17.893 0-33.41-5.875-43.915-14.805C18.636 98.196 10 85.231 9.265 66.986z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.988 90.285c-6.936.3-13.739 5.276-13.739 13.363s8.78 13.799 15.009 13.799c6.228 0 14.618-5.209 14.618-13.799s-8.952-13.662-15.888-13.363z"
        fill="#FDB323"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.09 99.911c-2.5-.055-4.416 2.008-4.416 3.879s1.693 3.819 4.385 3.878c2.692.059 4.311-1.773 4.311-3.644 0-1.871-1.778-4.058-4.28-4.113z"
        fill="#FCD7A0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.585 68.562c-5.784 0-5.784 7.9 0 7.9h24.825c5.952 0 5.952-7.9 0-7.9H90.585z"
        fill="#0156D6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.79 68.562c-6.569 0-6.569 7.9 0 7.9h26.276c6.7 0 6.7-7.9 0-7.9H28.79zM31.82 51.613h78.297c7.69 0 1.241-17.68-7.404-25.387-8.644-7.707-15.89-12.275-31.575-12.275-15.685 0-23.815 5.54-31.1 12.275-7.284 6.736-13.517 25.387-8.218 25.387z"
        fill="#0056D6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.817 45.063h68.012s-2.697-11.53-9.426-16.557c-6.73-5.026-12.37-8.005-24.58-8.005-12.21 0-18.54 3.613-24.21 8.005-5.671 4.393-9.796 16.557-9.796 16.557z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.207 46.7h9.214l7.316-7.93s1.817-2.41 0-4.197c-1.816-1.786-4.3-.29-4.3-.29L68.207 46.7z"
        fill="#0056D6"
      />
    </svg>
  )
}

export default UhSpotMeter
