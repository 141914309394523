import styled from 'styled-components';
import StreetSweeper from './icons/StreetSweeper.js';
import px2vw from '../../utils/px2vw';
import UhSpotMeter from './icons/UhSpotMeter.js';
import TrafficCop from './icons/TrafficCop.js';
import { useMedia } from 'react-media-hook';
import {BaseModalBackground} from 'styled-react-modal';
import {Link } from 'react-router-dom';
import AppStoreIcon from './icons/AppStoreIcon.js';
import GooglePlayIcon from './icons/GooglePlayIcon.js';

export const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
`;

export const Body = styled.div`
  display: flex;
  justify-content: center;
  height: ${px2vw(0)};
  margin: ${px2vw(32)} 0 0 0;

  @media (orientation: portrait) {
    height: ${px2vw(50)};
    margin: ${px2vw(70)} 0 0 0;
  }

  @media (max-width: 850px) {
    height: ${px2vw(50)};
    margin: ${px2vw(10)} 0 0 ${px2vw(300)};
  }
  @media (max-width: 500px){
    height: ${px2vw(3200)};
    margin: ${px2vw(100)} 0 0 ${px2vw(60)};
  }

`;

export const LeftContainer = styled.div`
  display: flex;
  width: ${px2vw(320, 320)};
  flex-direction: column;
  padding: ${px2vw(0)};
  margin: 0 ${px2vw(30)} 0 0;
  border-radius: ${px2vw(5)};
  background-color: ${props => props.bgColor};

  @media (min-width: 1024px) {
    width: ${px2vw(500)};
    min-height: ${px2vw(200)};
    height: 100%;
  }

  @media (orientation: portrait) {
    height: ${px2vw(500)};
    width: ${px2vw(600)};
  }

  @media (max-width: 500px){
    height: ${px2vw(2000)};
    width: ${px2vw(847)};
    padding: ${px2vw(0)} ${px2vw(250)} ${px2vw(0)} ${px2vw(0)};
    margin: ${px2vw(0)} ${px2vw(0)} ${px2vw(0)} ${px2vw(120)};
  }

  @media (max-width: 850px) and (max-height: 400px) {
    width: ${px2vw(450)};
  }
`;

export const RightContainer = styled.div`
  display: flex;
  width: ${px2vw(200)};
  height: ${props => props.height || px2vw(430)};
  flex-direction: column;
  padding: ${px2vw(0)};
  margin: ${px2vw(0)};
  border-radius: ${px2vw(5)};
  background-color: ${props => props.bgColor};

  @media (orientation: portrait) {
    padding: ${px2vw(0)} ${px2vw(0)} ${px2vw(70)} ${px2vw(0)};
  }

  @media (max-width: 500px){
    flex-direction: row;
    height: ${px2vw(247)};
    width: ${px2vw(1100)};
    padding: ${px2vw(0)} ${px2vw(0)} ${px2vw(800)} ${px2vw(0)};
    margin: ${px2vw(1050)} ${px2vw(0)} 0 ${px2vw(-550)};
  }

  @media (max-width: 850px) and (max-height: 400px) {
    flex-direction: column;
    padding: ${px2vw(0)} ${px2vw(0)} ${px2vw(0)} ${px2vw(0)};
  }
`;

export const PrimaryContainer = styled(LeftContainer)`
  margin: 0 0 ${px2vw(15)} 0;
  background-color: #E50000;

`
export const SecondaryContainer = styled(LeftContainer)`
  margin: ${px2vw(15)} 0 0 0;
  background-color: #FDB323;

  @media (max-width: 500px){
    margin: ${px2vw(70)} 0 0 0;
  }

  @media (max-width: 850px) and (max-height: 400px) {
    margin: ${px2vw(5)} 0 0 0;
  }
`;

export const TertiaryContainer = styled(RightContainer)`
  background-color: #42AAFF;
`;

export const Title = styled.h1`
  padding: ${props => props.padding || '0 0 0 0'};
  margin: ${px2vw(15)} 0 ${px2vw(10)} 0;
  font-size: ${px2vw(25)};
  color: #FFFFFF;

  @media (max-width: 500px){
    font-size: ${px2vw(150)};
    margin: ${px2vw(100)} 0 ${px2vw(0)} ${px2vw(50)};
    padding: 0 0 0 ${px2vw(0)};
  }
`;

export const Slogan = styled.h2`
  height: ${px2vw(0)};
  width: ${px2vw(400)};
  text-align: ${props => props.align || undefined};
  margin: ${props => props.margin || '0 0 0 0'};
  padding: ${props => props.padding || '0 0 0 0'};
  font-size: ${px2vw(20)};
  color: #FFFFFF;

  @media (max-width: 500px){
    font-size: ${px2vw(80)};
    width: ${px2vw(1000)};
    margin: ${px2vw(25)} 0 ${px2vw(0)} ${px2vw(50)};
    padding: 0 0 0 ${px2vw(0)}
  }

  @media (max-width: 850px) and (max-height: 400px) {
    margin: ${px2vw(0)} 0 ${px2vw(0)} ${px2vw(20)};
  }
`;

export const SecondarySlogan = styled(Slogan)`
  padding: ${px2vw(50)} 0 0 0;
  height: ${px2vw(190)};
  width: ${px2vw(200)};
  font-size: ${px2vw(20)};

  @media (max-width: 500px){
    padding: ${px2vw(0)} 0 0 0;
    height: ${px2vw(50)};
    width: ${px2vw(1000)};
    font-size: ${px2vw(80)};
  }

  @media (max-width: 850px) and (max-height: 400px) {
    padding: ${px2vw(30)} 0 0 0;
    width: ${px2vw(170)};
  }
`;
export const SubText = styled(Link)`
  margin: ${props => props.margin || `${px2vw(9)} 0 0 ${px2vw(28)}`};
  font-size: ${px2vw(8)};
  color: #FFFFFF;
    `;

export const SignUpButton = styled(Link)`
  cursor: 'pointer';
  margin: ${props => props.margin || `${px2vw(40)} ${px2vw(0)} ${px2vw(0)} ${px2vw(20)}`};
  padding: ${props => props.padding || '0 0 0 0'};

  border: none;
  font-size: ${px2vw(8)};
  font-family: 'Arial Rounded MT Bold';
  textAlign: center;

  @media (max-width: 500px){
    cursor: 'pointer';
    font-size: ${px2vw(50)};
    margin: ${px2vw(-165)} 0 ${px2vw(0)} ${px2vw(660)};
  }
`;

export const SignUpText = styled.text`
        width: ${px2vw(87)};
        height: ${px2vw(16)};
        font-family: 'Arial Rounded MT Bold';
        textAlign: center;
        color: #222B45
`;

const GraphicsDiv = styled.div`
    padding: 0 0 0 0;
    margin: ${props => props.margin || `${px2vw(20)} 0 0 ${px2vw(280)}`};

  @media (orientation: portrait) {
    margin: ${props => props.margin || `${px2vw(55)} ${px2vw(0)} ${px2vw(0)} ${px2vw(350)}`};
  }
`;

const MeterDiv = styled(GraphicsDiv)`
  margin : ${px2vw(90)} 0 0 ${px2vw(50)};

  @media (orientation: portrait) {
    margin: ${px2vw(160)} 0 0 ${px2vw(50)};
  }

  @media (max-width: 500px){
    margin: ${px2vw(500)} 0 0 ${px2vw(-250)};
  }

  @media (max-width: 850px) and (max-height: 400px) {
    margin: ${px2vw(110)} 0 0 ${px2vw(50)};
  }
`;

export const Meter = () => {
  const mobile_query = "(orientation: portrait) and (max-width: 500px)";
  const mobile = useMedia(mobile_query);
    return (
        <MeterDiv>
            <UhSpotMeter
            width={!mobile.matches ? px2vw(100) : px2vw(250)}
            height={!mobile.matches ? px2vw(100) : px2vw(700)} />
        </MeterDiv>
    )
}

const SweeperDiv = styled(GraphicsDiv)`
  padding: 0 0 0 0;
  margin: ${px2vw(-230)} 0 0 ${px2vw(180)};

  @media (orientation: portrait) {
    margin: ${px2vw(-150)} ${px2vw(0)} ${px2vw(0)} ${px2vw(200)};
  }

  @media (max-width: 500px){
    width: ${px2vw(50)};
    margin: ${px2vw(0)} 0 0 ${px2vw(80)};
  }

;`

export const Sweeper = () => {
  const mobile_query = "(orientation: portrait) and (max-width: 500px)";
  const mobile = useMedia(mobile_query);
    return (
        <SweeperDiv>
            <StreetSweeper
              width={!mobile.matches ? px2vw(500) : px2vw(900)}
              height={!mobile.matches ? px2vw(250) : px2vw(650)}
              viewBox={!mobile.matches ? "0 0 800 70" : `0 0 550 70`}
              />
        </SweeperDiv>
    )
}

const CopDiv = styled(GraphicsDiv)`
   padding: 0 0 0 0;
    margin: ${props => props.margin || `${px2vw(60)} 0 0 ${px2vw(320)}`};

  @media (orientation: portrait) {
    margin: ${props => props.margin || `${px2vw(95)} ${px2vw(0)} ${px2vw(0)} ${px2vw(350)}`};
  }

  @media (max-width: 500px){
    width: ${px2vw(50)};
    margin: ${px2vw(520)} 0 0 ${px2vw(750)};
  }

  @media (max-width: 850px) and (max-height: 400px) {
    width: ${px2vw(50)};
    margin: ${px2vw(60)} 0 0 ${px2vw(300)};
  }
;`

export const Cop = () => {
  const mobile_query = "(orientation: portrait) and (max-width: 500px)";
  const mobile = useMedia(mobile_query);
    return (
        <CopDiv >
            <TrafficCop
              width={!mobile.matches ? px2vw(150) : px2vw(300)}
              height={!mobile.matches ? px2vw(130) : px2vw(400)} />
        </CopDiv>
    )
}


const AppStoreDiv = styled.div`
  cursor: 'pointer';
  margin: ${props => props.margin || `${px2vw(40)} ${px2vw(0)} ${px2vw(0)} ${px2vw(20)}`};
  padding: ${props => props.padding || '0 0 0 0'};

  border: none;
  font-size: ${px2vw(8)};
  font-family: 'Arial Rounded MT Bold';
  textAlign: center;

  @media (max-width: 500px){
    cursor: 'pointer';
    font-size: ${px2vw(50)};
    margin: ${props => props.margin || `${px2vw(-275)} 0 ${px2vw(0)} ${px2vw(660)}`};
  }
;`

export const AppStoreLink = () => {
  const mobile_query = "(orientation: portrait) and (max-width: 500px)";
  const mobile = useMedia(mobile_query);
    return (
        <AppStoreDiv >
          <a rel="noopener noreferrer" href="https://apps.apple.com/us/app/uhspot/id1535252733" target="_blank">
            <AppStoreIcon
              width={!mobile.matches ? px2vw(125) : px2vw(400)}
              height={!mobile.matches ? px2vw(35) : px2vw(157)} />
          </a>
        </AppStoreDiv>
    )
}

export const GooglePlayLink = () => {
  const mobile_query = "(orientation: portrait) and (max-width: 500px)";
  const mobile = useMedia(mobile_query);
    return (
        <AppStoreDiv margin={mobile.matches ? `${px2vw(-40)} ${px2vw(0)} ${px2vw(0)} ${px2vw(660)}` : `${px2vw(10)} ${px2vw(0)} ${px2vw(0)} ${px2vw(20)}`} >
          <a rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.uhspot" target="_blank">
            <GooglePlayIcon
              width={!mobile.matches ? px2vw(125) : px2vw(400)}
              height={!mobile.matches ? px2vw(35) : px2vw(157)} />
          </a>
        </AppStoreDiv>
    )
}