import styled from 'styled-components';
import px2vw from '../../utils/px2vw';

const Link = ({ className, children }) => (
    <a className={className} href='/'>
      {children}
    </a>
  );


  export const Logo = styled.div`
  flex-wrap: wrap;
  max-width: 100%;
  @media (min-width: 1024px) {
    height: ${px2vw(0)};
    flex-wrap: nowrap;
  }

  @media (max-width: 500px){
  }
  `;

export const Row = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  justify-content: left;
  margin: ${px2vw(32)} 0 0 ${px2vw(350)};
  max-width: 100%;
  @media (min-width: 1024px) {
    height: ${px2vw(50)};
    flex-wrap: nowrap;
  }

  @media (orientation: portrait) {
    margin: ${px2vw(50)} 0 0 ${px2vw(300)};
  }

  @media (max-width: 500px){
    margin: ${px2vw(50)} 0 0 ${px2vw(100)};
  }
  `;
export const StyledLink = styled(Link)`
  margin: 2% 0 0 10%;
  color: #222B45;
  font-weight: bold;
  weight: 400;
  @media (max-width: 500px){
    margin: ${px2vw(170)} 0 0 ${px2vw(30)};
    font-size: ${px2vw(50)};
    white-space: nowrap;
  }
`;