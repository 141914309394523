import React from 'react';
import {
    Row,
    SocialMediaLinks,
    MiniSlogan,
    StyledLink} from './styles';
import UhSpotFooterLogo from './icons/UhSpotFooterLogo.svg';
import SocialMediaFooter from './icons/SocialMediaFooter.svg';
import px2vw from '../../utils/px2vw';

export const Footer = (props) => {

    // Supress warning coming from react styled component.
    const realError = console.warning;
    console.warning = (...x) => {
    // debugger;
    if (x[0] === 'Warning: The tag <text> is unrecognized in this browser. If you meant to render a React component, start its name with an uppercase letter.') {
        return;
    }
    realError(...x);
    };

    return (
        <>
        <Row margin = {props.margin}>
            <img src={UhSpotFooterLogo} alt="UhSpot footer logo" />
            <StyledLink bold="true"
                margin={
                    `${px2vw(0)} ${px2vw(132)} ${px2vw(0)} ${px2vw(0)}`
                }
                padding={
                    `0 ${px2vw(10)} 0 ${px2vw(130)}`
                }
                to='/support'
            >
                Support
            </StyledLink>
            <StyledLink bold="true"
                margin={
                        `${px2vw(0)} ${px2vw(132)} ${px2vw(0)} ${px2vw(0)}`
                    }
                padding={
                    `0 ${px2vw(15)} 0 0`
                    }
                    to='/about'
            >
                About
            </StyledLink>
            <StyledLink bold="true" to='/partners'>
                Partners
            </StyledLink>
            <MiniSlogan>
                The way on-street parking should be.
            </MiniSlogan>
            <SocialMediaLinks>
                <img src={SocialMediaFooter} alt="Social media footer"/>
            </SocialMediaLinks>
            <StyledLink fontSize='.7em' to='/terms'>
                2021 UhSpot Inc. All Rights Reserved.
            </StyledLink>
            <StyledLink fontSize='.7em' to='/terms'>
                Terms of Use
            </StyledLink>
            <StyledLink fontSize='.7em' to='/privacy'>
                Privacy Policy
            </StyledLink>
            <StyledLink fontSize='.7em' to='/eula'>
                End User License Agreement
            </StyledLink>
        </Row>
        </>
    )
}