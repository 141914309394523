export const apiConfig = {
    b2cScopes: ["https://uhspot.onmicrosoft.com/8aaefc48-f87c-4a8a-919d-55f66644699a/singup"],
    webApi: "https://uhspot.com/api/isup"
  };

  export const b2cPolicies = {
    names: {
        signUp: "B2C_1_uhspot_signup",
        signIn: "B2C_1_uhspot_sign_in",
        forgotPassword: "B2C_1_uhspot_reset_password",
        editProfile: "B2C_1_uhspot_profile_edit"
    },
    authorities: {
        signUp: {
            authority: "https://uhspot.b2clogin.com/uhspot.onmicrosoft.com/B2C_1_uhspot_signup",
        },
        signIn: {
            authority: "https://uhspot.b2clogin.com/uhspot.onmicrosoft.com/B2C_1_uhspot_sign_in",
        },
        forgotPassword: {
            authority: "https://uhspot.b2clogin.com/uhspot.onmicrosoft.com/B2C_1_uhspot_reset_password",
        },
        editProfile: {
            authority: "https://uhspot.b2clogin.com/uhspot.onmicrosoft.com/B2C_1_uhspot_edit_profile"
        }
    },
    authorityDomain: "uhspot.b2clogin.com"
}