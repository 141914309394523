import * as React from "react"

function GooglePlayIcon(props) {
  return (
    <svg
      width={661}
      height={196}
      viewBox="0 0 661 196"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"Google Play Download Icon"}</title>
      <g fill="none" fillRule="evenodd">
        <rect fill="#000" width={661} height={196} rx={22} />
        <text
          fontFamily="ProductSans-Regular, Product Sans"
          fontSize={45}
          fill="#FFF"
        >
          <tspan x={208.338} y={70}>
            {"GET IT ON"}
          </tspan>
        </text>
        <text
          fontFamily="ProductSans-Regular, Product Sans"
          fontSize={80}
          fill="#FFF"
        >
          <tspan x={207.48} y={147}>
            {"Google Play"}
          </tspan>
        </text>
        <path
          d="M56.1 37.817c-.86.67-2.1 2.487-2.1 6.169v102.859c0 6.769.928 9.176 3.404 10.677 2.476 1.5 9.598-.928 11.402-1.859 51.621-26.628 91.875-46.949 100.148-52.256 1.21-.776 4.046-3.148 4.046-5.786 0-2.637-2.289-4.374-3.445-4.99-16.83-8.97-96.106-49.055-107.2-54.814-2.84-1.474-5.452-.626-6.256 0z"
          fill="#01D3FC"
        />
        <path
          d="M56.1 37.817c-.862.66 84.287 80.104 86.08 79.175 12.166-6.306 18.492-9.299 26.771-14.53 1.21-.765 4.049-3.104 4.049-5.704 0-2.6-2.29-4.312-3.448-4.92-16.843-8.841-96.182-48.356-107.285-54.032-2.842-1.453-5.363-.606-6.168.011z"
          fill="#02EC77"
        />
        <path
          d="M55.174 155.121c.052.239.667 1.324 1.774 2.097 1.106.774 1.344.695 2.087.782 3.599.42 9.2-2.01 10.544-2.7 51.352-26.363 91.377-46.845 99.607-52.1 1.203-.767 3.631-3.033 3.449-6.093-.183-3.06-1.912-4.258-3.062-4.868-11.162-5.92-20.19-10.647-27.084-14.181-58.245 51.216-87.35 76.904-87.315 77.063z"
          fill="#FC3846"
        />
        <path
          d="M119.843 97.923l21.215 20.493c13.244-6.666 22.659-11.728 28.244-15.187 1.224-.758 3.698-2.274 3.698-6.045 0-3.77-2.277-4.743-3.448-5.345-11.363-5.845-20.384-10.439-27.063-13.781l-22.646 19.865z"
          fill="#FDCA00"
        />
      </g>
    </svg>
  )
}

export default GooglePlayIcon