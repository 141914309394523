import * as React from "react"

function AppStoreIcon(props) {
    const width = props.width || 665;
    const height = props.height || 196;
    const viewBox = props.viewBox || "0 0 665 196"
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"AppStore Download Icon"}</title>
      <g fill="none" fillRule="evenodd">
        <rect fill="#000" width={665} height={196} rx={22} />
        <path
          d="M127.81 64.892c4.11-1.854 8.498-3.656 16.482-2.557 10.235 1.408 18.233 6.037 23.995 13.888-10.492 6.461-15.737 15.31-15.737 26.545 0 11.235 6.483 20.481 19.45 27.74-9.347 19.02-17.85 30.443-25.51 34.27-7.124 3.56-13.21 1.256-19.594-1.008l-.783-.277c-3.664-1.286-7.443-2.48-11.587-2.48-4.15 0-7.877 1.38-11.422 2.815l-.643.261c-6.194 2.525-11.873 4.972-18.345.145-10.377-7.739-22.34-20.315-27.597-45.845-5.257-25.53 4.379-39.312 9.919-45.41 5.54-6.097 10.748-9.772 22.633-10.644 11.885-.873 13.097 4.68 25.455 5.589 4.943.364 8.238-.796 11.394-2.183l.63-.28.63-.284zM138.756 30c1.028 8.655-1.19 16.314-6.653 22.977C126.64 59.64 119.606 62.98 111 63c.042-9.349 2.445-16.955 7.211-22.818 4.766-5.863 11.614-9.257 20.545-10.182z"
          fill="#FFF"
        />
        <text fontFamily="Helvetica" fontSize={48} fill="#FFF">
          <tspan x={221.402} y={82}>
            {"Download on"}
          </tspan>
        </text>
        <text
          fontFamily="Helvetica-Bold, Helvetica"
          fontSize={72}
          fontWeight="bold"
          fill="#FFF"
        >
          <tspan x={223.498} y={154}>
            {"App Store"}
          </tspan>
        </text>
      </g>
    </svg>
  )
}

export default AppStoreIcon
