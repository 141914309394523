import React from 'react';
import GoogleMaps from '../../components/GoogleMaps';


function Analytics() {

  return (
      <>
    <GoogleMaps >
    </GoogleMaps>
  </>
  );

}

export default Analytics;