import React from 'react';
import UhSpotLogo from './icons/UhSpotLogo.svg';
import { StyledLink, Row, Logo } from './styles';
import {SignIn} from '../../components/Auth/SignIn';
import px2vw from '../../utils/px2vw';
import { useMedia } from 'react-media-hook';

export const Header = () => {
    const mobile_query = "(orientation: portrait) and (max-width: 500px)";
    const mobile = useMedia(mobile_query);
    return (
        <>
        <Row>
            <Logo>
            <img src={UhSpotLogo} alt="UhSpot logo"/>
            </Logo>
            <StyledLink>
                UhSpot
            </StyledLink>
            <StyledLink>
                Our Vision.
            </StyledLink>
            <StyledLink>
                Join Us.
            </StyledLink>
            <SignIn margin={
                !mobile.matches ?
                    `${px2vw(14)} 0 0 ${px2vw(100)}` :
                    `${px2vw(150)} 0 0 ${px2vw(55)}`
                }/>
        </Row>
        </>
    )
}