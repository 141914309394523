import styled from 'styled-components';
import px2vw from '../../../utils/px2vw';
import {SignUpButton} from '../styles';

export const SignInButton = styled(SignUpButton)`
margin: ${props => props.margin || `${px2vw(0)} ${px2vw(0)} ${px2vw(0)} ${px2vw(0)}`};
padding: ${props => props.padding || '0 0 0 0'};
width: ${px2vw(100)};
height: ${px2vw(30)};
border-radius: ${px2vw(25)};
border-style: solid;
border-width: thin;
border: 1;

@media (max-width: 500px){
  width: ${px2vw(200)};
  height: ${px2vw(100)};
  font-size: ${px2vw(50)};
  margin: ${props => props.margin || `${px2vw(0)} ${px2vw(0)} ${px2vw(0)} ${px2vw(0)}`};
  padding: ${props => props.padding || '0 0 0 0'};
  border-radius: ${px2vw(100)};
}
`

export const ProfileButton = styled(SignInButton)`

`