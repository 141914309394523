import styled from 'styled-components'
import px2vw from '../../utils/px2vw';
import {Link } from 'react-router-dom';



  export const Row = styled.div`
    margin: ${props => props.margin ||
      `0 0 0 ${px2vw(350)}`};

    @media (min-width: 768px) {
      height: ${px2vw(0)};
      bottom: ${px2vw(0)};
      flex-wrap: nowrap;
    }

    @media (min-width: 1020px) {
      height: ${px2vw(0)};
    }

    @media (orientation: portrait) {
      margin: ${props => props.margin || 
        `${px2vw(500)} 0 0 ${px2vw(305)}`};
    }

    @media (min-height: 1024px) {
      height: ${px2vw(0)};
      bottom: ${px2vw(100)};
      flex-wrap: nowrap;
    }

    @media (max-width: 500px){
      margin: ${px2vw(50)} 0 0 ${px2vw(50)};
    }
  `;

export const StyledLink = styled(Link)`
    margin: ${props => props.margin || (
      `${px2vw(0)} ${px2vw(30)} ${px2vw(0)} ${px2vw(0)}`)};
    padding: ${props => props.padding || '0 0 0 0'};
    font-weight : ${props => props.bold ? 'bold' : undefined};
    font-size: ${props => props.fontSize || undefined};
    color: #222B45;
`;

export const SocialMediaLinks = styled.div`
    margin: ${px2vw(-50)} 0 0 0;
    padding: ${props => props.padding || '0 0 0 0'};
`;

export const MiniSlogan = styled.text`
    display: flex;
    font-family: 'Arial Rounded MT Bold';
    margin: ${props => props.margin || '1% 0 5% 0'};
    padding: ${props => props.padding || '0 0 0 0'};
    font-size: ${props => props.fontSize || '1em'};
    opacity: .3;
`