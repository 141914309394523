import React, { useState } from 'react';
import {Header} from '../Header';
import { Footer } from '../Footer';

function Partners() {

    return (
        <>
            <Header />
            <Footer />
        </>
        )
}

export default Partners;