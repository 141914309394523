import styled from 'styled-components'
import px2vw from '../../utils/px2vw';
import {Link } from 'react-router-dom';

export const Body = styled.div`
  height: 100%;
  margin: ${px2vw(10)} ${px2vw(500)} 0 ${px2vw(500)};
  
  @media (orientation: portrait) {
    height: 100%;
    margin: ${px2vw(70)} ${px2vw(250)} 0 ${px2vw(250)};
  }

  @media (max-width: 850px) {
    height: 100%;
    margin: ${px2vw(10)} ${px2vw(250)} 0 ${px2vw(250)};
  }
  @media (max-width: 500px){
    height: 100%;
    margin: ${px2vw(100)} 0 0 ${px2vw(60)};
  }

`;

export const TermsHeaderDiv = styled.div`
    justify-content: center;
    display: block;
`

export const TermsBodyDiv = styled.div`
    display: block;
`

export const TermsHeaderText = styled.text`
    justify-content: center;
    font-family: 'Arial Rounded MT Bold';
    white-space: pre-wrap;
    font-size: ${props => props.fontSize || '2em'};
`


export const TermsBodyText = styled.text`
    font-family: 'Arial Rounded MT Bold';
    font-size: ${props => props.fontSize || '.8em'};
`