import React, { useState } from 'react';
import {
    Body,
    LeftContainer,
    RightContainer,
    PrimaryContainer,
    Title,
    Slogan,
    SignUpButton,
    SecondaryContainer,
    TertiaryContainer,
    SecondarySlogan,
    Meter,
    Sweeper,
    Cop,
    AppStoreLink,
    GooglePlayLink,
} from './styles.js';
import {Header} from '../Header';
import { Footer } from '../Footer';
import px2vw from '../../utils/px2vw';
import Carousel from '../../components/Carousel';
import {
    useMsal,
    AuthenticatedTemplate,
    UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "../../components/Auth/authConfig";
import { Link } from 'react-router-dom';

function Main() {
    const { instance } = useMsal();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleLogin = (loginType) => {
        setAnchorEl(null);

        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                return;
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest);
        }
    }

    return (
        <>
        <Header/>
        <Body>
            <LeftContainer>
                <PrimaryContainer>
                    <Title padding={`0 0 0 ${px2vw(15)}`}>
                        UhSpot
                    </Title>
                    <Slogan padding={`0 0 0 ${px2vw(15)}`}>
                        The way on-street parking should be.
                    </Slogan>
                            <AppStoreLink />
                            <GooglePlayLink />
                    <Sweeper/>
                </PrimaryContainer>
                <SecondaryContainer>
                <Slogan align='center' margin={`${px2vw(10)} 0 0 ${px2vw(15)}`}>
                <Carousel navigateText={"Learn More."}>
                        {
                            [
                            `Sign up for a chance to win our
                            free garage parking give-away`,
                            `Become an UhSpot user today to start earning`
                            ]
                            }
                </Carousel>
                </Slogan>
                <Cop/>
                </SecondaryContainer>
            </LeftContainer>
            <RightContainer>
                <TertiaryContainer>
                    <SecondarySlogan align='center'>
                        <Carousel navigateText={"Learn More."}>
                            {
                                [
                                    `Our AI powered parking navigation
                                    system makes sure you’re in
                                    the right place, at the right
                                    time for that oh'so glorious parking spot.`,
                                    `Once you find your parking spot,
                                    celebrate by sharing with others in the
                                    community!`
                                ]
                            }
                        </Carousel>
                    </SecondarySlogan>
                <Meter/>
                </TertiaryContainer>
            </RightContainer>
        </Body>
        <Footer margin ={`${px2vw(500)} 0 0 ${px2vw(350)}`}/>
        </>
    )
}

export default Main;