import styled from 'styled-components';
import px2vw from '../../utils/px2vw';

export const SignUpButton = styled.button`
  margin: ${props => props.margin || `${px2vw(0)} ${px2vw(0)} ${px2vw(0)} ${px2vw(-320)}`};
  padding: ${props => props.padding || '0 0 0 0'};
  width: ${px2vw(100)};
  height: ${px2vw(30)};
  border-radius: ${px2vw(25)};
  border: none;
  background: #FFFFFF;
  font-size: ${px2vw(8)};
  font-family: 'Arial Rounded MT Bold';
  textAlign: center;

  @media (max-width: 500px){
    width: ${px2vw(400)};
    height: ${px2vw(125)};
    font-size: ${px2vw(50)};
    margin: ${px2vw(-165)} 0 ${px2vw(0)} ${px2vw(660)};
    border-radius: ${px2vw(100)};
  }
`;

export const Body = styled.div`
  display: flex;
  justify-content: center;
  height: ${px2vw(0)};
  margin: ${px2vw(32)} 0 0 0;

  @media (orientation: portrait) {
    height: ${px2vw(50)};
    margin: ${px2vw(70)} 0 0 0;
  }

  @media (max-width: 850px) {
    height: ${px2vw(50)};
    margin: ${px2vw(10)} 0 0 ${px2vw(300)};
  }
  @media (max-width: 500px){
    margin: ${px2vw(100)} 0 0 ${px2vw(0)};
  }

`;