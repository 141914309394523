import React from 'react';
import { GoogleMap, InfoWindow, LoadScript, Marker, StandaloneSearchBox, OverlayView } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '1350px'
  };

  const center = {
    lat: 40.679278,
    lng: -73.955645
  };

  const centerOverlayView = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  });


  const position = center;

  const divStyle = {
    background: `white`,
    padding: 10
  }

  const startInputStyle = {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `240px`,
    height: `32px`,
    padding: `0 12px`,
    borderRadius: `3px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    position: 'absolute',
    top: '100px',
    left: '20px',
  }

  const endInputStyle = {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `240px`,
    height: `32px`,
    padding: `0 12px`,
    borderRadius: `3px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    position: 'absolute',
    top: '150px',
    left: '20px',
  }

  const onClick = (...args) => {
    console.log('onClick args: ', args)
  }

  const contentStyles = {
    background: `white`,
    border: `1px solid #CCC`,
    padding: 15,
    top: 300,
    left: 300
  }

  
const GoogleMaps = () => {
    const [overlayPosition, setOverlayPosition] = React.useState(center);
    const [overlayPane, setOverlayPane] = React.useState(
        OverlayView.OVERLAY_MOUSE_TARGET
      );

      const CenterControl = (controlDiv, map) => {
        // Set CSS for the control border.
        const controlUI = document.createElement("div");
        controlUI.style.backgroundColor = "#fff";
        controlUI.style.border = "2px solid #fff";
        controlUI.style.borderRadius = "3px";
        controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
        controlUI.style.cursor = "pointer";
        controlUI.style.marginBottom = "22px";
        controlUI.style.textAlign = "center";
        controlUI.title = "Click to recenter the map";
        controlDiv.appendChild(controlUI);
        // Set CSS for the control interior.
        const controlText = document.createElement("div");
        controlText.style.color = "rgb(25,25,25)";
        controlText.style.fontFamily = "Roboto,Arial,sans-serif";
        controlText.style.fontSize = "16px";
        controlText.style.lineHeight = "38px";
        controlText.style.paddingLeft = "5px";
        controlText.style.paddingRight = "5px";
        controlText.innerHTML = "Center Map";
        controlUI.appendChild(controlText);
        // Setup the click event listeners: simply set the map to Chicago.
        controlUI.addEventListener("click", () => {
          map.setCenter(center);
        });
      }

    const loadCallback = React.useCallback((e) => {
        console.log('OverlayView onLoad: ', e)
      }, []);
    const unmountCallback = React.useCallback((e) => {
        console.log('OverlayView onUnmount', e)
      }, []);
    const clickHandler = React.useCallback(() => {
        alert('You clicked overlay view')
    }, []);
    
    const onLoad = React.useCallback((map) => {
        const centerControlDiv = document.createElement("div");
        CenterControl(centerControlDiv, map);
        map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(centerControlDiv)

    }, [])
      return (
        <LoadScript
          googleMapsApiKey="AIzaSyBfK5Yiz-LLQB-Gk487ADEVsIMQai4AbVM"
          language='en'
          region='EN'
          libraries={["places"]}
          mapIds={['b8ba018a524dae6a']}
          disableDefaultUI={true}
          
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={17}
            onClick={onClick}
            onLoad={onLoad}
          >
            <StandaloneSearchBox>
          <input
            type='text'
            placeholder='Starting Address'
            style={startInputStyle}
          />
        </StandaloneSearchBox>
        <StandaloneSearchBox>
          <input
            type='text'
            placeholder='Ending Address'
            style={endInputStyle}
          />
        </StandaloneSearchBox>
        {
            <OverlayView
              position={overlayPosition}
              mapPaneName={overlayPane}
              onLoad={loadCallback}
              onUnmount={unmountCallback}
              getPixelPositionOffset={centerOverlayView}
            >
              <button
                type='button'
                style={contentStyles}
                onClick={clickHandler}
              >
                <h1>OverlayView</h1>
              </button>
            </OverlayView>
        }
            <Marker position={position}/>
            <InfoWindow position={position}>
                <div style={divStyle}>
                    <h1>Hi there!</h1>
                    <p> voy paya</p>
                </div>
            </InfoWindow>
          </GoogleMap>
        </LoadScript>
      )

}


export default React.memo(GoogleMaps);