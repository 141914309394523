import React, {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import CarouselItem from './CarouselItem';
import px2vw from '../../utils/px2vw';

const Link = ({ className, children }) => (
  <a className={className} href="/">
    {children}
  </a>
);

const CarouselComponent = styled.div`
  text-align: center;
`;

const CarouselInner = styled.ul`
  position: relative;
  overflow: hidden;
  padding: 0;
`;

const CarouselControls = styled.div`
`;


const CarouselLink = styled.li`
  margin: ${px2vw(0)} 0 0 0;
  display: block;
  font-size: ${px2vw(10)};

  @media (max-width: 500px){
    font-size: ${px2vw(50)};
        }
`;

export const SubText = styled(Link)`
        margin: ${props => props.margin || `${px2vw(0)} 0 0 ${px2vw(0)}`};
        color: #FFFFFF
    `;

const Carousel = (props) => {
    const {navigateText, autoScroll, autoScrollInterval, children} = props;
    const [activeIndex, setActiveIndex] = useState(0);
    const rotate = useRef(0);


    useEffect(() => {

            if (autoScroll && children.length > 1) {
              rotate.current = setInterval(() => {
                setActiveIndex(getAutoIndex());
              }, autoScrollInterval);
            }

            return () => {
              if (rotate.current) {
                clearInterval(rotate.current);
              }
            };
          }, [activeIndex, children, autoScrollInterval, autoScroll, getAutoIndex]);

          function displayChildren() {
            return (
              children.map((child, index) => {
                return (
                  <CarouselItem
                    activeIndex={activeIndex}
                    index={index}
                    item={child}
                    key={index}
                  />
                );
              })
            );
          }

          function linkNavigate(event) {
            const index = getAutoIndex();
            setActiveIndex(parseInt(index, 10));
          }

          function navigate(event){
            const {current} = rotate;
            const index = event.currentTarget.getAttribute('data-index');
            setActiveIndex(parseInt(index, 10));
            if (current) {
              clearInterval(current);
            }
          }

          function getAutoIndex() {
            const lastIndex = children.length - 1;
            let autoIndex;

            if (activeIndex === 0) {
                autoIndex = activeIndex + 1;
            } else if (activeIndex === lastIndex) {
                autoIndex = 0;
            } else {
                autoIndex = activeIndex + 1;
            }
              return autoIndex;
            }

          function linkCarousel(props) {
            if (children.length > 1) {
              return (
                <CarouselLink
                  onClick={linkNavigate}
                >
                  <SubText> {props} </SubText>
                </CarouselLink>
              );
            } else {
              return null;
            }
          }

          return (
            <CarouselComponent>
              <CarouselInner>
                {displayChildren()}
              </CarouselInner>
              <CarouselControls>
                {linkCarousel(navigateText)}
              </CarouselControls>
            </CarouselComponent>
          );
}

export default Carousel;