import { useState } from "react";
import {
        useMsal,
        AuthenticatedTemplate,
        UnauthenticatedTemplate } from "@azure/msal-react";
import {SignInButton, ProfileButton} from "./styles.js";
import {b2cPolicies} from "../constants"


export const SignIn = (props) => {
    const {margin, padding, ...other} = props;
    const { instance } = useMsal();
    const {acountId, setAccountId} = useState(null);
    const {username, setUsername} = useState(null)

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const selectAccount = () => {
        /**
         * See here for more info on account retrieval:
         * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
         */
        const currentAccounts = instance.getAllAccounts();
        if (currentAccounts.length === 0) {
            return;
        } else if (currentAccounts.length > 1) {
            // Add your account choosing logic here
            console.log("Multiple accounts detected.");
        } else if (currentAccounts.length === 1) {
            setAccountId(currentAccounts[0].homeAccountId);
            setUsername(currentAccounts[0].username);
            //welcomeUser(username);
        }
    }


    const handleResponse = (response) => {
        /**
         * To see the full list of response object properties, visit:
         * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#response
         */
        if (response !== null) {
            setAccountId(response.account.homeAccountId);
            setUsername(response.account.username);
            welcomeUser(username);
        } else {
            selectAccount();
        }
    }


    const handlePolicyChange = (response) => {
        /**
         * We need to reject id tokens that were not issued with the default sign-in policy.
         * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
         * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */

        if (response.idTokenClaims['acr'] === b2cPolicies.names.editProfile) {
            window.alert("Profile has been updated successfully. \nPlease sign-in again.");
            instance.logout();
        } else if (response.idTokenClaims['acr'] === b2cPolicies.names.forgotPassword) {
            window.alert("Password has been reset successfully. \nPlease sign-in with your new password.");
            instance.logout();
        }
    }

    const handleLogin = (loginType) => {
        setAnchorEl(null);
        if (loginType === "popup") {
            instance.loginPopup(b2cPolicies.authorities.signIn)
            .then(handleResponse)
            .catch(e => {
                // Error handling
                if (e.errorMessage) {
                    // Check for forgot password error
                    // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
                    if (e.errorMessage.indexOf("AADB2C90118") > -1) {
                        instance.loginPopup(b2cPolicies.authorities.forgotPassword)
                            .then(response => handlePolicyChange(response));
                    }
                }
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(b2cPolicies.authorities.signIn.authority);
        }
    }

    const welcomeUser = (username) => {
            return (
                <ProfileButton >
                    Thanks for Signing up!
                </ProfileButton>
            )
    }
    return (
        <>
            <UnauthenticatedTemplate>
                <SignInButton
                    margin={margin}
                    padding={padding}
                    onClick={() => handleLogin("popup")}
                    key="signinPopup">
                Login
                </SignInButton>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <ProfileButton>
                    Welcome to UhSpot!
                </ProfileButton>
            </AuthenticatedTemplate>
        </>
    )

}