import { MsalProvider, useMsal } from "@azure/msal-react";
import { EventType, InteractionType } from "@azure/msal-browser";
import {b2cPolicies} from "./components/Auth/constants";

import Main from './screens/Main';
import Analytics from './screens/Analytics';
import Partners from './screens/Partners';
import {TermsOfUse} from './screens/TermsOfUse';
import {PrivacyPolicy} from './screens/PrivacyPolicy';
import {Copyright} from './screens/Copyright';
import {EULA} from './screens/EULA';
import { useEffect } from "react";
import { HashRouter, BrowserRouter } from 'react-router-dom';
import {Switch, Route } from 'react-router';

function App({ pca }) {
  return (
    <BrowserRouter basename="/">
    	<MsalProvider instance={pca}>
			<Switch>
				<Pages/>
    		</Switch>
      </MsalProvider>
    </BrowserRouter>
  );
}

function Pages() {
	const { instance } = useMsal();

	useEffect(() => {
		const callbackId = instance.addEventCallback((event) => {
			if (event.eventType === EventType.LOGIN_FAILURE) {
				if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
					if (event.interactionType === InteractionType.Redirect) {
						instance.loginRedirect(b2cPolicies.forgotPassword);
					} else if (event.interactionType === InteractionType.Popup) {
						instance.loginPopup(b2cPolicies.forgotPassword).catch(e => {
							return;
						});
					}
				}
			}
		});

		return () => {
			if (callbackId) {
				instance.removeEventCallback(callbackId);
			}
		};
	}, [instance]);

	return (
			<Switch>
				<Route exact path='/' component={Main} />
				<Route exact path='/analytics' component={Analytics} />
				<Route exact path='/partners' component={Partners} />
				<Route exact path='/terms' component={TermsOfUse} />
				<Route exact path='/privacy' component={PrivacyPolicy} />
				<Route exact path='/eula' component={EULA} />
			</Switch>
	)
}

export default App;
